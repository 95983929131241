import React from 'react'

import '../../../App.css';
import Header from './Header';


const Home = () => {
  return (
    <>
        <Header/>
    </>
  )
}

export default Home;

